@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button:active {
  transform: scale(0.98);
  transform: translateY(3px);
}

.auth button {
  align-items: center;
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 40px;
  margin: 10px;
}

.auth button.active {
  background: #4645C2;
}

.auth button:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.auth button:hover {
  background: #4645C2;
}

.background-with-blur::after {
  @apply absolute inset-0 bg-gradient-to-br from-transparent to-[#363058] blur-[50px] pointer-events-none;
  content: '';
  width: 30%; /* Adjust the size of the blur effect area */
  height: 30%;
  top: 70%;
  left: 70%;
}


.text-gradient {
  @apply bg-clip-text text-transparent;
  background-image: linear-gradient(90deg, #B7B7FF, #4F5EE5);
}

.connect-wallet-btn {
  background: linear-gradient(0deg, #644FE5, #4645C2);
  padding: 20px 30px;
  border-radius: 30px;
  margin: 30px 0;
}

.connect-wallet-btn:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.card-background {
  background: linear-gradient(0deg, rgba(61, 61, 61, 0.2), rgba(61, 61, 61, 0.48));
  /* background: rgba(61, 61, 61, 0.2); */
  border-radius: 20px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.33);
  border-width: 1px 1px 0 1px;
}

.price-card-background {
  background: linear-gradient(0deg, rgba(61, 61, 61, 0.2), rgba(61, 61, 61, 0));
  /* background: rgba(61, 61, 61, 0.2); */
  border-radius: 20px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.33);
  border-width: 1px 1px 0 1px;
}

.card-background-1 {
  background: linear-gradient(0deg, rgba(61, 61, 61, 0.2), rgba(61, 61, 61, 0.48));
  border-radius: 20px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.33);
  border-width: 1px 1px 0 1px;
}

.card-background:hover {
  transform: scale(1.1);
}

.get-started-btn {
  padding: 10px 20px;
  border-radius: 30px;
  background: linear-gradient(0deg, #B7B7FF, #4F5EE5);
}

.get-started-btn:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.background-with-blur-protect::after {
  @apply absolute inset-0 bg-gradient-to-br from-transparent to-[#363058] blur-[100px] pointer-events-none;
  content: '';
  width: 50%; /* Adjust the size of the blur effect area */
  height: 50%;
  top: 0;
  left: 70%;
}

.market-plan::after {
  @apply absolute inset-0 bg-gradient-to-br from-[#866BDC] to-transparent blur-[200px] pointer-events-none;
  content: '';
  width:30%; /* Adjust the size of the blur effect area */
  height: 70%;
  top: 30%;
  left: 0;
}

.user-number-card::before {
  content: '';
  display: block;
  background-image: url('/public/img/staticTop.png');
  background-size: cover;
  position: absolute;
  left: 100px;
  top: -90px;
  width: 170px;
  height: 170px;
}

.user-number-card::after {
  content: '';
  display: block;
  background-image: url('/public/img/staticBottom.png');
  background-size: cover;
  position: absolute;
  right: 50px;
  bottom: -150px;
  width: 300px;
  height: 300px;
}

.trusted-wallet-card::before {
  content: '';
  display: block;
  background-image: url('/public/img/fintech/decorate1.png');
  background-size: cover;
  position: absolute;
  left: 100px;
  top: -90px;
  width: 170px;
  height: 170px;
}

.trusted-wallet-card::after {
  content: '';
  display: block;
  background-image: url('/public/img/fintech/decorate2.png');
  background-size: cover;
  position: absolute;
  right: 50px;
  bottom: -150px;
  width: 300px;
  height: 300px;
}

.footer {
  /* background: linear-gradient(180deg, #151623, #545889); */
  background: #151623;
}

.footer ul {
  padding: 0 20px;
  line-height: 3;
}

.footer ul li {
  font-size: 13px;
}

.footer ul li:first-child {
  font-size: 16px;
  font-weight: 800;
}

.reload-btn {
  padding: 10px 20px;
  border-radius: 30px;
  background: linear-gradient(0deg, #644FE5, #4645C2);
}

.reload-btn:active {
  transform: scale(0.98);
  box-shadow: 0 1px #0056b3;
  transform: translateY(3px);
}

.country-select ul {
  background-color: #090B17; /* TailwindCSS color - gray-50 */
}

.country-select button {
  border-radius: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
}

.country-select ul li:hover {
  background-color: gray;
}

.country-select ul div {
  background-color: #090B17;
}

.country-select ul div input {
  background-color: #090B17;
}

#mainChart-ChartLabel-title tspan:first-child {
  fill: white !important;
  font-weight: bold !important;
}

#subChart1-ChartLabel-title tspan:first-child {
  fill: white !important;
  font-size: 13px !important;
}

#carousel {
  position: absolute;
  height: 200px;
  width: 1600px;
  margin: auto;
  left: 0;
  right: 0;
  top: 200px;
  bottom: 0;
}

.arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: white;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  color: #228291;
  line-height: 30px;
  margin-top: 85px;
  z-index: 1000;
}

.arrow-right {
  left: 780px;
}

.item {
  text-align: center;
  color: white;
  font-size: 40px;
  position: absolute;
  transition: height 1s, width 1s, left 1s, margin-top 1s, line-height 1s, background-color 1s;
}

.level-2 {
  height: 220px;
  width: 310px;
  line-height: 220px;
  left: 1550px;
  margin-top: 25px;
  top: 70px;
  z-index: 0;
}

.level-2 img {
  height: 220px;
  width: 310px;
}

.level-1 {
  height: 260px;
  width: 370px;
  line-height: 260px;
  left: 1150px;
  margin-top: 10px;
  top: 60px;
  z-index: 999;
}

.level-1 img {
  height: 260px;
  width: 370px;
}

.level0 {
  height: 400px;
  width: 670px;
  line-height: 400px;
  left: 465px;
}

.level0 img {
  height: 400px;
  width: 670px;
}

.level1 {
  height: 260px;
  width: 370px;
  line-height: 260px;
  margin-top: 10px;
  left: 80px;
  top: 60px;
}

.level1 img {
  height: 260px;
  width: 370px; 
}

.level2 {
  height: 220px;
  width: 310px;
  line-height: 220px;
  margin-top: 25px;
  left: -260px;
  top: 70px;
}

.level2 img {
  height: 220px;
  width: 310px;
}

.left-enter {
  opacity: 0;
  left: -470px; /* Calculated as -220px (level2-left) - 310px (level2-width) */
  height: 190px; /* 220px (level2-height) - 30px */
  width: 290px; /* 310px (level2-width) - 20px */
  line-height: 190px;
  margin-top: 40px;
}

.left-enter.left-enter-active {
  opacity: 1;
  left: -220px; /* level2-left */
  height: 220px;
  width: 310px;
  line-height: 220px;
  margin-top: 25px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.left-leave {
  opacity: 1;
  left: 1550px; /* level-2-left */
  height: 220px;
  width: 310px;
  line-height: 220px;
  margin-top: 25px;
}

.left-leave.left-leave-active {
  left: 1880px; /* 1550px (level-2-left) + 310px (level2-width) + 20px */
  opacity: 0;
  height: 190px; /* 220px - 30px */
  line-height: 120px;
  margin-top: 40px;
  width: 290px; /* 310px - 20px */
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-enter {
  opacity: 0;
  left: 1860px; 
  height: 190px; /* 220px - 30px */
  width: 290px; /* 310px - 20px */
  line-height: 190px;
  margin-top: 40px;
}

.right-enter.right-enter-active {
  left: 1550px; /* level-2-left */
  opacity: 1;
  height: 220px;
  margin-top: 25px;
  line-height: 220px;
  width: 310px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.right-leave {
  left: -220px; /* level2-left */
  height: 220px;
  opacity: 1;
  margin-top: 25px;
  line-height: 220px;
  width: 310px;
}

.right-leave.right-leave-active {
  left: -530px; /* -220px (level2-left) - 310px (level2-width) */
  opacity: 0;
  height: 190px; /* 220px - 30px */
  width: 290px; /* 310px - 20px */
  line-height: 190px;
  margin-top: 40px;
  transition: left 1s, opacity 1s, height 1s, width 1s, margin-top 1s, line-height 1s;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#thank-you-message {
  display: none;
}

#thank-you-message.show {
  display: block;
}

#no-thank-you-message {
  display: none;
}

#no-thank-you-message.show {
  display: block;
}